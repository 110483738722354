import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
// import isEqual from 'react-fast-compare'
import moment from 'moment';
import { withLocalize } from 'react-localize-redux'
import Layout from './../../Layout'
import Loader from './../../Layout/Loader'
import Typography from '@mui/material/Typography'
import EmptyState from './../../Components/common/EmptyState'
import {
  checkPrivileges,
  getDateTimeFormat,
  PaginationConfig,
} from '../../Helpers'
import { RemoteReactTable } from '../../Components/common/ReactBaseTable'
import axios from 'axios'
import { exportFile } from '../../Components/common/ExportExcel'
import ConfirmDialoag from '../../Components/common/ConfirmDialoag';


Object.defineProperty(Array.prototype, 'chunk', {
  value: function (chunkSize) {
    var R = []
    for (var i = 0; i < length; i += chunkSize)
      R.push(slice(i, i + chunkSize))
    return R
  }
})


const Messages = (props) => {

  const [state, setState] = useState({
    from: '',
    to: '',
    selectedDate: '',
    detail: false,
    loading: false,
    tableLoading: false,
    validatedForm: false,
    device: {},
    device1: {},
    currentPage: 1,
    callSent: false,
    pagination: {
      ...PaginationConfig,
      pageSize: 20,
    },
    data: [],
    attributes: [],
    isExport: false,
    query: '',
    dateDialogOpen: false,
  });

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      validatedForm: !!(state.device && state.device.value)
    }));
  }, [state.device]);

  // useEffect(() => {
  //   const timezone = props.ServerSetting?.attributes?.timezone || props.logInUser?.attributes?.timezone || 'Asia/Dubai';
  //   moment.tz.setDefault(timezone);
  // }, [props.ServerSetting, props.logInUser]);

  if (!checkPrivileges('position')) {
    props.history.push('/');
    return null;
  }

  const selectDevice = (e) => {
    setState(prevState => ({
      ...prevState,
      device: { ...e },
      validatedForm: !!(e && e.value)
    }));
  };

  const changeDetail = (name, e) => {
    setState(prevState => ({ ...prevState, detail: e.target.checked }));
  };

  const selectDate = (e, date) => {
    const adjustedEndDate = date.endDate.clone().set({ hour: 23, minute: 59, second: 59, millisecond: 999 });

    let timezone = 'Asia/Dubai';
    if (
      props.ServerSetting &&
      props.ServerSetting.attributes &&
      props.ServerSetting.attributes.timezone
    ) {
      timezone = props.ServerSetting.attributes.timezone;
    }
    if (
      props.logInUser &&
      props.logInUser.attributes &&
      props.logInUser.attributes.timezone
    ) {
      timezone = props.logInUser.attributes.timezone;
    }
    moment.tz.setDefault(timezone);

    var formattedDate = {
      from: moment(date.startDate?.format('YYYY-MM-DD HH:mm')).toISOString(),
      // to: adjustedEndDate.toISOString(),
      to: moment(date.endDate?.format('YYYY-MM-DD HH:mm')).toISOString()
    }
    const daysDifference = adjustedEndDate.diff(date.startDate, 'days');
    if (daysDifference > 2) {
      setState((prevState) => ({
        ...prevState,
        dateDialogOpen: true
      }))

    } else {
      setState((prevState) => ({
        ...prevState,
        dateDialogOpen: false
      }))
    }
    let timeFormat = getDateTimeFormat();
    setState((prevState) => ({
      ...prevState,
      selectedDate:
        moment(formattedDate.from)?.format(timeFormat) +
        ' - ' +
        moment(formattedDate.to)?.format(timeFormat),
      ...formattedDate,
      validatedForm: prevState.device && prevState.device.value ? true : false,
    }));
  };
  const onDelete = () => {
    setState((prevState) => ({
      ...prevState,
      dateDialogOpen: false,
      selectedDate: "",
      from: "",
      to: ""
    }))
  }

  // const calcTime = (date, offset) => {
  //   const d = new Date(date);
  //   const utc = d.getTime() + d.getTimezoneOffset() * 60000;
  //   return new Date(utc + 3600000 * offset).toISOString();
  // };

  const getAttributes = async (device) => {
    return await axios.get(`/api/attributes/computed?deviceId=${device}&all=true`)
  }

  const createMessagesReport = (reset, type) => {
    if (type === 'excel') {
      let Dform = {
        from: state.from,
        to: state.to,
        device: state.device.id,
        detail: state.detail,
      };
      exportFile('Dlogs', Dform);
    } else {
      setState((prevState) => ({
        ...prevState,
        loading: true,
        callSent: false,
      }));
      getAttributes(state.device.value).then(({ status, data }) => {
        if (status === 200) {
          const attributes = [];
          if (data && data.length) {
            data.forEach((d) => {
              if (d.category > 0) {
                attributes.push(d.attribute);
              }
            });
          }
          attributes.push('totalDistance', 'hours', 'stopTime', 'idlTime', 'parking');
          setState((prevState) => ({
            ...prevState,
            attributes,
          }));
          let query = '?';
          if (state.validatedForm) {
            if (state.device && state.device.value) {
              query += '&deviceId=' + state.device.value;
            }
            if (state.to) {
              query += '&to=' + state.to;
            }
            if (state.from) {
              query += '&from=' + state.from;
            }
            query += '&detail=' + state.detail;
            getMoreItems(query);
          }
        }
      });
    }
  };

  const getMoreItems = (query) => {
    setState(prevState => ({ ...prevState, query, callSent: true, tableLoading: false, loading: false }))
  }

  // const checkFloteNumber = (n) => Number(n) === n && n % 1 !== 0;

  // const handleChangeRowsPerPage = (value) => {
  //   setState(prevState => ({
  //     ...prevState,
  //     pagination: {
  //       ...prevState.pagination,
  //       pageSize: value,
  //       page: 1
  //     },
  //     currentPage: 1
  //   }));
  //   createMessagesReport();
  // };

  // const handleChangePage = (page, pageSize) => {
  //   setState(prevState => ({
  //     ...prevState,
  //     pagination: {
  //       ...prevState.pagination,
  //       page,
  //       pageSize
  //     },
  //     currentPage: page
  //   }));
  //   createMessagesReport();
  // };

  // const SearchItem = (searchValue) => {
  //   setState(prevState => ({
  //     ...prevState,
  //     pagination: {
  //       ...prevState.pagination,
  //       page: 1,
  //       itemSearch: searchValue
  //     }
  //   }));
  //   createMessagesReport();
  // };

  // const fetchData = (page, pageSize) => {
  //   if (!state.tableLoading) {
  //     setState(prevState => ({ ...prevState, tableLoading: true }));
  //     handleChangePage(page, pageSize);
  //   }
  // };

  const { query } = state;
  const serverTimeZoneName = props.ServerSetting?.attributes?.timezone || props.logInUser?.attributes?.timezone || 'Asia/Dubai';


  if (checkPrivileges('position')) {
    return (
      <div>
        <Layout
          {...props}
          classFromChildren='has-padding'
          selectDate={selectDate}
          selectDevice={selectDevice}
          selectedDevice={state.device}
          validatedForm={state.validatedForm}
          createMessagesReport={createMessagesReport}
          changeDetail={changeDetail}
          {...state}
        >
          <div className='main-content-page'>
            <div
              style={{
                background: props.themecolors.backgroundColor,
                color: props.themecolors.textColor,
                borderRadius: 6
              }}
            >
              {!state.loading && !state.callSent && (
                <EmptyState
                  text={props.translate('noTrackersSelected')}
                />
              )}
              {!state.loading ? (
                <React.Fragment>
                  <Typography
                    variant='h5'
                    component='h4'
                    style={{
                      margin: 0,
                      color: 'inherit',
                      padding: '15px 20px'
                    }}
                  >
                    {state.device1.label}
                  </Typography>
                  {state.callSent ? <RemoteReactTable
                    serverTimeZone={serverTimeZoneName}
                    data={state.data}
                    columns={!state.detail ?
                      [
                        {
                          Header: props.translate('address'),
                          accessor: 'address',
                          width: 250
                        },
                        {
                          Header: props.translate('time'),
                          accessor: 'fixTime',
                          headerClassName: 'text-center',
                          width: 150,
                          Cell: ({ value }) => {
                            let timeFormat = getDateTimeFormat();
                            let value1 = moment.tz(value, serverTimeZoneName)?.format(timeFormat);
                            return value1
                          },
                        },
                        {
                          Header: props.translate('positionLatitude'),
                          accessor: 'latitude',
                          headerClassName: 'text-center',
                          width: 75
                        },
                        {
                          Header: props.translate('positionLongitude'),
                          accessor: 'longitude',
                          headerClassName: 'text-center',
                          width: 75
                        },
                        {
                          Header: props.translate(
                            'sensorsTranslation.speed'
                          ),
                          accessor: 'speed',
                          headerClassName: 'text-center',
                          width: 55
                        },
                        {
                          Header: props.translate('parameters'),
                          accessor: row => {
                            const attr = row.attributes;
                            const arr = Object.keys(attr).filter(r => r !== 'unknown' && !state.attributes.includes(r));
                            return arr.map(key => key + "=" + attr[key]).join(', ')
                          },
                          headerClassName: 'text-left',
                          width: 400
                        },
                        {
                          Header: props.translate('sensorValue'),
                          accessor: row => {
                            const attr = row.attributes;
                            return state.attributes.map(key => key + "=" + (attr[key] !== undefined ? attr[key] : "")).join(', ')
                          },
                          headerClassName: 'text-left',
                          width: 400
                        },
                        {
                          Header: props.translate('course'),
                          accessor: 'course',
                          headerClassName: 'text-center',
                          width: 55
                        },
                        {
                          Header: props.translate('altitude'),
                          accessor: 'altitude',
                          headerClassName: 'text-center',
                          width: 60
                        },
                        {
                          Header: props.translate('serverTime'),
                          accessor: 'serverTime',
                          headerClassName: 'text-left',
                          width: 150,
                          Cell: ({ value }) => {
                            let timeFormat = getDateTimeFormat();
                            let value1 = moment.tz(value, serverTimeZoneName)?.format(timeFormat);
                            return value1
                          },
                        }
                      ] :
                      [
                        {
                          Header: props.translate('address'),
                          accessor: 'address',
                          width: 250
                        },
                        {
                          Header: props.translate('time'),
                          accessor: 'fixTime',
                          headerClassName: 'text-center',
                          width: 150,
                          Cell: ({ value }) => {
                            let timeFormat = getDateTimeFormat();
                            let value1 = moment.tz(value, serverTimeZoneName)?.format(timeFormat);
                            return value1
                          },
                        },
                        {
                          Header: props.translate('positionLatitude'),
                          accessor: 'latitude',
                          headerClassName: 'text-center',
                          width: 75
                        },
                        {
                          Header: props.translate('positionLongitude'),
                          accessor: 'longitude',
                          headerClassName: 'text-center',
                          width: 75
                        },
                        {
                          Header: props.translate(
                            'sensorsTranslation.speed'
                          ),
                          accessor: 'speed',
                          headerClassName: 'text-center',
                          width: 55
                        },
                        {
                          Header: props.translate('course'),
                          accessor: 'course',
                          headerClassName: 'text-center',
                          width: 55
                        },
                        {
                          Header: props.translate('altitude'),
                          accessor: 'altitude',
                          headerClassName: 'text-center',
                          width: 60
                        },
                        {
                          Header: props.translate('serverTime'),
                          accessor: 'serverTime',
                          headerClassName: 'text-left',
                          width: 150,
                          Cell: ({ value }) => {
                            let timeFormat = getDateTimeFormat();
                            let value1 = moment.tz(value, serverTimeZoneName)?.format(timeFormat);
                            return value1
                          },
                        },
                        {
                          Header: props.translate('parking'),
                          accessor: 'attributes.parking',
                          headerClassName: 'text-left',
                          width: 55
                        },
                        {
                          Header: props.translate('hours'),
                          accessor: 'attributes.hours',
                          headerClassName: 'text-left',
                          width: 60
                        },
                        {
                          Header: props.translate('distance'),
                          accessor: 'attributes.distance',
                          headerClassName: 'text-left',
                          width: 55
                        },
                        {
                          Header: props.translate('motion'),
                          accessor: 'attributes.motion',
                          headerClassName: 'text-left',
                          width: 55
                        },
                        {
                          Header: props.translate('fuel2'),
                          accessor: 'attributes.fuel2',
                          headerClassName: 'text-left',
                          width: 55
                        },
                        {
                          Header: props.translate('hdop'),
                          accessor: 'attributes.hdop',
                          headerClassName: 'text-left',
                          width: 55
                        },
                        {
                          Header: props.translate('input'),
                          accessor: 'attributes.input',
                          headerClassName: 'text-left',
                          width: 55
                        },
                        {
                          Header: props.translate('idlTime'),
                          accessor: 'attributes.idlTime',
                          headerClassName: 'text-left',
                          width: 55
                        },
                        {
                          Header: props.translate('binUniqueId'),
                          accessor: 'attributes.binUniqueId',
                          headerClassName: 'text-left',
                          width: 55
                        },
                        {
                          Header: props.translate('customDiscrete2'),
                          accessor: 'attributes.customDiscrete2',
                          headerClassName: 'text-left',
                          width: 140
                        },
                        {
                          Header: props.translate('MC'),
                          accessor: 'attributes.MC',
                          headerClassName: 'text-left',
                          width: 55
                        },
                        {
                          Header: props.translate('driverUniqueId'),
                          accessor: 'attributes.driverUniqueId',
                          headerClassName: 'text-left',
                          width: 150
                        },
                        {
                          Header: props.translate('accumulatorReset'),
                          accessor: 'attributes.accumulatorReset',
                          headerClassName: 'text-left',
                          width: 140
                        },
                        {
                          Header: props.translate('stopTime'),
                          accessor: 'attributes.stopTime',
                          headerClassName: 'text-left',
                          width: 55
                        },
                        {
                          Header: props.translate('power'),
                          accessor: 'attributes.power',
                          headerClassName: 'text-left',
                          width: 55
                        },
                        {
                          Header: props.translate('totalDistance'),
                          accessor: 'attributes.totalDistance',
                          headerClassName: 'text-left',
                          width: 55
                        },
                        {
                          Header: props.translate('adc0'),
                          accessor: 'attributes.adc0',
                          headerClassName: 'text-left',
                          width: 55
                        },
                        {
                          Header: props.translate('ignition'),
                          accessor: 'attributes.ignition',
                          headerClassName: 'text-left',
                          width: 55
                        }
                      ]
                    }
                    query={`/api/positions/get${query}`}
                  /> : null}
                </React.Fragment>
              ) : null}
              {state.loading && <Loader />}

              {state.dateDialogOpen && (
                <ConfirmDialoag
                  onCancel={onDelete}
                  onOk={onDelete}
                  title={props.translate('Alert !')}
                  children={"You can not select more then 3 days"}
                  hideCancel
                />
              )}
            </div>
          </div>
        </Layout>
      </div>
    )
  } else {
    props.history.push('/')
    return null
  }
}

const mapStateToProps = state => ({
  themecolors: state.themeColors,
  logInUser: state.logInUsers,
  ServerSetting: state.ServerSetting
})

export default connect(mapStateToProps)((withLocalize(Messages)))